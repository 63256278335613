export const exponents = [1, 2, 3, 4, 8, 12];

export const kanjiNames = [
  "十",
  "百",
  "千",
  "万",
  "億",
  "兆",
  "京",
  "垓",
  "𥝱",
  "穣",
  "溝",
  "澗",
  "正",
  "載",
  "極",
  "恒河沙",
  "阿僧祇",
  "那由他",
  "不可思議",
  "無量大数",
];

export const kanjiNums = [
  "",
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "七",
  "八",
  "九",
];

export const suujiList = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
